import React, { FC } from 'react'
import { graphql } from 'gatsby';
import Slider from 'react-slick'

const ContentfulReviewSlider: FC = ({data}) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  }
  return (
    <section className="homepage-review-slider">
      <h1 className="headline">{data.reviewHeadline}</h1>
      <p className="quotations">“</p>
      <Slider {...settings} className="slider">

        {data.reviewCard.map(item => {
                
                return <div className="review" key={item.id}>
                <p className="body">{item.body.body}</p>
                <p className="name">{item.customerName}</p>
            </div>
        })}

      </Slider>
    </section>
  )
}
export default ContentfulReviewSlider

export const query = graphql`
  fragment ContentfulReviewSliderFragment on ContentfulReviewSlider {
    __typename
    id
    name
    reviewHeadline: headline
    headerText {
      raw
    }
    reviewCard {
      name
      id
      customerName
      body {
        body
      }
    }
  }
`