import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import Footer from '../components/footer'

import '../pages/index'

type Props = {
  children: React.ReactNode
}

const Layout: FC<Props> = ({ children }: Props) => {
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/ews6chy.css" />
      </Helmet>

      {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
      <div>
        {/* -- This is where all the components show up */}
        <main>{children}</main>
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
