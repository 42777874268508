import React, { FC } from 'react'
import { graphql } from 'gatsby';
import ContentfulServicesAboutBody from './partials/servicesAboutBody'

const ContentfulServicesAbout: FC = ({ data }) => {
    return (
        <section className="services-about">
          <div className="contents">
            <div className="flex-desktop">
              <div className="row">
                <div className="column">
                  <div className="main">
                    <h1 className="headline">{data.mainHeadline}</h1>
                    <div className="headline-body"><ContentfulServicesAboutBody richText={data.servicesAboutBody} /></div>
                  </div>
                  {data?.helpsWithIcons?.length > 0 &&
                    <div className="helps-with">
                        <p className="subhead">HELPS WITH:</p>
                        <div className="helps-with__icons">
                            {data?.helpsWithIcons?.map(icon => {
                                return <div className="icon" key={icon.id}>
                                    <div className="img-wrap">
                                        <img src={icon?.art?.url} alt={icon?.text}/>
                                    </div>
                                    <p>{icon?.text}</p>
                                </div>
                            })}
                        </div>
                    </div>
                    }
                </div>
                {data?.mainImage ?
                    <div className="image">
                        <img src={data.mainImage.url} alt="Main" />
                    </div> : ''
                }
              </div>
              <div className="row bottom-row">
                <a className="button mobile-only" href="#contact-form">
                  SCHEDULE NOW
                </a>
                {data?.howItWorksCopy ?
                    <div className="how-it-works">
                        {data.howItWorksHeadline ? <h1>{data.howItWorksHeadline}</h1> : <h1>How it works:</h1>}
                        <p>{data.howItWorksCopy.howItWorksCopy}</p>
                    </div> : ''
                }
                {data?.loveItListItem?.length > 0 ?
                    <div className="list">
                        {data?.whyYoullLoveItHeadline ? <h1>{data.whyYoullLoveItHeadline}</h1> : <h1>Why you&apos;ll love it:</h1>}
                        <ul>
                            {data.loveItListItem.map(item => {
                                return <li className="" key={item.id}>{item.listItem}</li>
                            })}
                        </ul>
                    </div> : ''
                }
              </div>
            </div>
          </div>
        </section>
    );
};

export default ContentfulServicesAbout

export const query = graphql`
  fragment ContentfulServicesAboutFragment on ContentfulServicesAbout {
      __typename
      id
      name
      breadcrumbOnly
      mainImage {
        url
      }
      whyYoullLoveItHeadline
      mainHeadline
      headline {
        id
      }
      secondHeadline
      servicesAboutBody {
        raw
      }
      helpsWithIcons {
        id
        text
        art {
          url
        }
      }
      howItWorksCopy {
        howItWorksCopy
      }
      loveItListItem {
        id
        listItem
      }
  }
`