import React from 'react';

const Home: React.FC = () => {
  if (typeof window !== 'undefined') {
    window.location.href = '/botox/';
  }

  return null;
};

export default Home;
