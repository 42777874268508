import React, { FC, useEffect, useState } from 'react'
import { graphql } from 'gatsby';

//import useMediaQuery from '../../hooks/mediaQuery';
import LogoFull from '../../assets/images/svg/js/logo-full'
import ContentfulPromoHeroOffer from './partials/promoHeroOffer'
import StaticThanks from '../global/thanks'

const ContentfulPromoHero: FC = ({ data }) => {
    const [isSubmitted, setFormSubmitted] = useState(false);
    //let isMobile = useMediaQuery('(max-width: 768px)');

    const phoneNumeric = data?.location?.phone.replace(/\D/g, '');

    useEffect(() => {
        window.addEventListener('message', function (e) {
          if (e.data.action === 'LiineWidgetFormSubmitted') {
            setFormSubmitted(true);
          }
        })
      }, [])

    return (
        <section className={data.ctaLabel ? `hero has-top-bar` : `hero`} style={data.bgColor ? {backgroundColor: data.bgColor } : {}}>
            <div className="top-bar">{data.ctaLabel &&
                <div className="contents">
                    <p>{data.ctaLabel}</p>
                    {data?.location?.phone && <a href={`tel:${phoneNumeric}`}>CALL {data?.location?.phone}</a>}
                </div>
            }</div>

            <a className="logo" href="https://www.mychapter.com"><LogoFull /></a>

            <div className="hero-content">
                <h2>{data.title}</h2>
                {data.dividerImage?.url && <img className="divider" src={data.dividerImage.url} alt="Divider" />}
                <p className="summary">{data.subtitle}</p>

                {data.offer && <div className="offer-content"><ContentfulPromoHeroOffer richText={data.offer} /></div>}

                {/* {data?.location?.zenotiCenterId &&
                    <a className="link-cta" target="_blank" rel="noreferrer"
                    href={`https://chapter.zenoti.com/webstoreNew/services/${data?.location?.zenotiCenterId}`}
                    >Schedule a Complimentary Consultation</a>
                }  */}
            </div>

            {data?.formBg && (
                <div className="form-bg-image" style={{
                    backgroundImage: `url("${data.formBg.url}")`
                }}><div className="form-bg-white"></div></div>
            )}

            <div className="hero-heading"><h1>{data.heading}</h1></div>

            <div className="iframe-wrapper">
                {isSubmitted===true ? (
                    <StaticThanks />
                ) : (
                    <iframe
                        title="form"
                        width="100%"
                        height="100%"
                        id="liine-form-widget"
                        frameBorder="no"
                        src={data.liineIframeSrc}
                    ></iframe>
                )}
            </div>


                <div className="bg-image" style={{
                    backgroundImage: `url("${data.desktopBg.url}")`
                }}></div>


            {/* <div className="bottom-bar" />  */}

        </section>
    )
};

export default ContentfulPromoHero

export const query = graphql`
    fragment ContentfulPromoHeroFragment on ContentfulPromoHero {
        __typename
        id
        heading
        title
        bgColor
        desktopBg {
          url
        }
        mobileBg {
          url
        }
        formBg {
          url
        }
        dividerImage {
          url
        }
        ctaLabel
        liineIframeSrc
        subtitle
        offer {
          raw
        }
        location {
          name
          address1
          city
          state
          zipCode
          phone
          zenotiCenterId
        }
      }
`