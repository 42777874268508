import React, { useEffect, FC } from 'react'
import { graphql } from 'gatsby';

const ContentfulFrequentlyAskedQuestions: FC = ({ data }) => {
  useEffect(() => {
    var acc = document.getElementsByClassName('accordion')
    var i

    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener('click', function (this: HTMLElement) {
        this.classList.toggle('active')
      })
    }
  }, [])

  return (
    <section className="faq">
      <div className="contents">
        <h1>Frequently Asked Questions</h1>
        {data.question.map(item => {
                return <button className="accordion" key={item.id}>
                    {item.headline}
                    <div className="panel">
                        <ul dangerouslySetInnerHTML={ {__html: item.listItemHtml.listItemHtml} }>

                        </ul>
                    </div>
            </button>
        })}

      </div>
    </section>
  )
}

export default ContentfulFrequentlyAskedQuestions

export const query = graphql`
  fragment ContentfulFrequentlyAskedQuestionsFragment on ContentfulFrequentlyAskedQuestions {
    __typename
    id
    name
    buttonCopy
    buttonHref
    question {
      name
      id
      headline
      listItemHtml {
        listItemHtml
      }
    }
  }
`