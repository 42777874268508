import React from 'react';

const Footer: React.FC = () => {
  return (
    <>
      <section className="footer">
        <div className="container">
          <div className="flower">
            <img src="https://images.ctfassets.net/m8zwsu9tyucg/5iGoNjUATHdT4qbWELjEVS/6fec41adbfb080f75811fe6cb5d29fc7/flower.svg" width="45" height="262" />
          </div>
          <div className="contents">
            <div className="left">
              <p>
                Visit Us At <a href="https://www.mychapter.com">MYCHAPTER.COM</a>
              </p>
            </div>
            <div className="right">
              <p>
                © 2022 CHAPTER. ALL RIGHTS RESERVED. <br /> <a href="https://www.mychapter.com/about/terms">TERMS OF USE</a>
                <br /> <a href="https://www.mychapter.com/about/privacy-policy">HIPAA PRIVACY POLICY</a>
              </p>
            </div>
          </div>
        </div>
        <div className="loc-space">{/* This is here as a space holder for mobile when the location bar is stuck to the bottom.  */}</div>
      </section>
    </>
  );
};

export default Footer;
