import React, { Component } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'

import ContentfulPromoHero from '../components/contentful/promoHero'
import ContentfulServicesAbout from '../components/contentful/servicesAbout'
import ContentfulBeforeAndAfterCarousel from '../components/contentful/beforeAndAfterCarousel'
import ContentfulFrequentlyAskedQuestions from '../components/contentful/frequentlyAskedQuestions'
import ContentfulReviewSlider from '../components/contentful/reviewSlider'
import ContentfulHtml from '../components/contentful/html'

class PromoPage extends Component {
  render() {
    const renderComponent = (module, index) => {
      if (module?.id && module?.__typename) {
        switch (module?.__typename) {
          case 'ContentfulPromoHero':
            return <ContentfulPromoHero data={module} key={module?.id} />
          case 'ContentfulServicesAbout':
            return <ContentfulServicesAbout data={module} key={module?.id} />
          case 'ContentfulBeforeAndAfterCarousel':
            return <ContentfulBeforeAndAfterCarousel data={module} key={module?.id} />
          case 'ContentfulFrequentlyAskedQuestions':
            return <ContentfulFrequentlyAskedQuestions data={module} key={module?.id} />
          case 'ContentfulReviewSlider':
            return <ContentfulReviewSlider data={module} key={module?.id} />
          case 'ContentfulHtml':
            return <ContentfulHtml data={module} key={module?.id} />
          default:
            console.log(module.__typename + ' not implemented')
            return <div key={index}>{module.__typename}</div>;
        }
      } else {
        return '';
      }
    };
    return (
      <>
          {this.props.data.contentfulPromoPage.components.map((module, index) => {
            return renderComponent(module, index);
          })}          
      </>  
    )
  }
}

PromoPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default PromoPage

export const pageQuery = graphql`
  query promoPageQuery ($slug:String!) {
    contentfulPromoPage(slug: {eq: $slug}) {
      id
      slug
      components {
        ...ContentfulPromoHeroFragment
        ...ContentfulServicesAboutFragment
        ...ContentfulBeforeAndAfterCarouselFragment
        ...ContentfulFrequentlyAskedQuestionsFragment
        ...ContentfulReviewSliderFragment
        ...ContentfulHtmlFragment
      }
    }
  }
`

