/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC } from 'react'
import { graphql } from 'gatsby';
import Slider from 'react-slick';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ContentfulBeforeAndAfterCarousel: FC = ({ data }) => {
  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <section className="before-and-after-carousel">
      <div className="contents">
        <div className="copy copy-container">
          <h1>{data.beforeAndAfterHeadline}</h1>
          <p>{data.body}</p>
          <a className="button desktop-only" href="#contact-form">
            SCHEDULE NOW
          </a>
        </div>
        <div className="slider">
          {/* @ts-ignore */}
          <Slider {...settings}>
            {data.card.map(item => {
              return (
                <div className="card" key={item.id}>
                  <LazyLoadImage src={item.cardBackgroundImage.url} alt={item.name} />
                </div>
              )
            })}
          </Slider>
          <a className="button mobile-only" href="#contact-form">
            SCHEDULE NOW
          </a>
        </div>
      </div>
    </section>
  );
};

export default ContentfulBeforeAndAfterCarousel;

export const query = graphql`
  fragment ContentfulBeforeAndAfterCarouselFragment on ContentfulBeforeAndAfterCarousel {
    __typename
    id
    name
    beforeAndAfterHeadline: headline
    body
    buttonCopy
    buttonHref
    card {
      id
      name
      cardBackgroundImage {
        url
      }
    }
  }
`