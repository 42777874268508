import React, { FC } from 'react'

const StaticThanks: FC = () => {
  return (
        <section className="thanks">
            <div className="contents">
            <img
              className="logo"
              src="https://chapter.s3.amazonaws.com/chapter-thank-you.613f97d4c2be68.98932095.jpg"
              alt="Chapter"
            />
            <h1>Thank You for getting in touch!</h1>
            <p>One of our team members will be in touch with you soon</p>
            <p>- The Chapter team</p>
            </div>
        </section>
  )
}


export default StaticThanks