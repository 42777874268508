const pkg = require('./package.json'); // eslint-disable-line @typescript-eslint/no-var-requires

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  require('dotenv').config({
    path: `.env.${process.env.NODE_ENV}`,
  })
}

// App configs
exports.APP_NAME = pkg.name;
exports.APP_DESC = pkg.description;
exports.APP_DEPS = pkg.dependencies;
exports.APP_VERSION = pkg.version;
exports.BUILD_DATE = new Date().toISOString();
exports.APP_PATH = process.env.APP_PATH || '/';
exports.APP_SITE = process.env.APP_SITE || 'https://promos.mychapter.com/';
exports.APP_BG_COLOR = process.env.APP_BG_COLOR || '#f7f0eb';
exports.APP_COLOR = process.env.APP_COLOR || '#a2466c';
exports.APP_ICON = process.env.APP_ICON || 'src/images/chapter-icon.png'

// Module Federation configs
exports.MF_PLATFORM_NAME = process.env.MF_PLATFORM_NAME;
exports.MF_PLATFORM_REMOTE = process.env.MF_PLATFORM_REMOTE;

// CMS configs
exports.CMS_TOKEN = process.env.CONTENTFUL_ACCESS_TOKEN
exports.CMS_PREVIEW_TOKEN = process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN
exports.CMS_PREVIEW_SECRET = process.env.CONTENTFUL_PREVIEW_SECRET
exports.CMS_SPACE_ID = process.env.CONTENTFUL_SPACE_ID
exports.CMS_ENV = process.env.CONTENTFUL_ENVIRONMENT_ID


// Supporting configs
exports.BUGSNAG_KEY = process.env.GATSBY_BUGSNAG_API_KEY
exports.GTM_ID = process.env.GATSBY_GOOGLE_TAG_MANAGER_ID
exports.VWO_ID = process.env.GATSBY_VWO_ACCOUNT_ID
exports.GOOGLE_API_KEY = process.env.GATSBY_GOOGLE_API_KEY