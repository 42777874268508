import React, { FC } from 'react'
import { graphql } from 'gatsby';

const ContentfulHtml: FC = ({data}) => {
return (
    <div className="html-box" dangerouslySetInnerHTML={ {__html: data.htmlBox.htmlBox } } />
);
}
export default ContentfulHtml

export const query = graphql`
  fragment ContentfulHtmlFragment on ContentfulHtml {
    __typename
    id
    htmlBox {
      htmlBox
    }
  }
`