import Bugsnag from '@bugsnag/js';
import { version } from './package.json';
import './src/assets/scss/main.scss';
const CONFIG = require('./config'); // eslint-disable-line @typescript-eslint/no-var-requires

if (CONFIG.BUGSNAG_KEY) {
  Bugsnag.start({
    apiKey: CONFIG.BUGSNAG_KEY,
    appVersion: version,
    endpoints: {
      notify: 'https://bugsnag-notify.aspendental.com',
      sessions: 'https://bugsnag-sessions.aspendental.com',
    },
  });
}
